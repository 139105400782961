import { computedAsync } from '@vueuse/core';
import { MaybeRef, unref } from 'vue';
import { getAppointments } from '../api';

export const useAppointmentsAsync = (fromDate: MaybeRef<Date>, toDate: MaybeRef<Date>, refreshToggle: MaybeRef<boolean> = true) =>
    computedAsync(async (onCancel) => {
        const abortController = new AbortController();

        onCancel(() => abortController.abort());
        return (await getAppointments(fromDate, toDate, abortController.signal)).data;
    });
